import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProductPageTemplate from "../../components/productPageTemplate"

const StallPage = () => {
  const query = useStaticQuery(graphql`
    query stallQuery {
      mainImage: file(relativePath: { eq: "bike-mount_2.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      secondImage: file(relativePath: { eq: "IMG_0148.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      thirdImage: file(relativePath: { eq: "IMG_0149.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      fourthImage: file(relativePath: { eq: "bike-mount_3.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const product = {
    name: "Diagonalhängt cykelställ",
    mainImage: query.mainImage.childImageSharp.fluid,
    secondImage: query.secondImage.childImageSharp.fluid,
    fourthImage: query.thirdImage.childImageSharp.fluid,
    thirdImage: query.fourthImage.childImageSharp.fluid,
    secondImageNarrow: true,
    thirdImageNarrow: false,
    fourthImageNarrow: true,
    description:
      "Vinkelmonteringen gör att cyklar hänger extremt nära varandra, utan att fastna eller hindra varandra vid upphängning eller användning.",
    facts: (
      <ul>
        <li>Snygg, hållbar, enkel.</li>
        <li>Spar upp till 40% av golvytan</li>
        <li>Inga tunga lyft</li>
        <li>
          Tillval:
          <ul>
            <li>
              Låswire monterad för att använda eget befintligt lås eller
              xtra-lås
            </li>
            <li>Tillverkad i rostfritt 18.8-stål</li>
            <li>Montering på skena för extremt bra sidoutnyttjande</li>
            <li>Monteringsarbete kontakta oss</li>
          </ul>
        </li>
      </ul>
    ),
    specs: (
      <ul>
        <li>Design av proffesionellt skola</li>
        <li>Tillverkad i stöd plåt, 2 stansade stycken</li>
        <li>Pulverlack,vattenbaserad milj-lack</li>
        <li>5 olika kulörer, tillval er egen färg</li>
        <li>Avrinningshål för skyddande av hållare o däck</li>
        <li>Vikt 2400g</li>
        <li>Mått. H320,b270,t 110.</li>
        <li>Monteringshöjd 900-1300 mm</li>
        <li>Svensktillverkad rundade kanter för minimalt skaderisk</li>
      </ul>
    ),
  }

  return <ProductPageTemplate product={product} />
}
export default StallPage
